import TwoHalf from '@components/TwoHalf';
import photo2 from '@img/photo--2.webp'
// import s from './AboutBlock.module.scss';
const AboutBlock = () => {
    return (
        <section className="container section">
            <TwoHalf fxRevers img={photo2} alt='Никита Колодезников-Матчитов' >
                <h3 className="title3">Никита Колодезников-Матчитов</h3>
                <strong >Основатель мастерской</strong>
                <br /><br /><br />
                <p >
                    С детства я был окружен творческими людьми, мой дедушка был строителем и мастером по дереву. Спустя много лет и мне передалась его любовь к дереву - в свободное время мастерил для себя небольшие  изделия из древесины.
                </p>
                <br />
                <p >Более десяти лет назад построил двухэтажный гараж, на втором этаже расположилась наша мастерская. Начинал производство практически “с нуля”, с каждой зарплаты понемногу откладывал на покупку инстурментов и станков. </p>
                <br />
                <p >Постепенно начали появляться первые заказы. Несколько лет назад мы с супругой решили еще заняться ремонтом и реставрацией мебели. Эту идею предложили сами клиенты, поскольку периодически приходили с таким запросом. В основном, реставрацией занимается супруга Саргылана. Мы с ней одна сплоченная, дружная и отличная команда.  </p>
            </TwoHalf>
        </section>
    );
};

export default AboutBlock;