export const homePageAdvantagelist = [
    'Производство авторской мебели из массива',
    'Реставрация, редизайн, ремонт деревянной мебели',
    'Производство мебели по индивидуальным размерам'
]
export const aboutPageAdvantagelist = [
    {
        title: '+10 лет',
        description:'На рынке по производству мебели'
    },
    {
        title: '+1500',
        description:'Изготовлено мебели'
    },
    {
        title: '120 лет',
        description:'Самой старинной мебели для реставрации '
    },
]