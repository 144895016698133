export const navList1 = [
    {
        path: '/workshop',
        text:'Мастер-классы'
    },
    {
        path: '/furniture',
        text:'Мебель на заказ'
    }

]
export const navList2 = [
    {
        path: '/contacts',
        text:'Контакты'
    },
    {
        path: '/about',
        text:'О нас'
    },

]
export const links = [
    {
        path: '/privacy',
        text:'Политика кофиденциальности'
    },
    {
        path: '/offer',
        text:'Договор оферты'
    },

]