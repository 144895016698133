import imgServeces1 from './../assets/img/serveces--1.webp'
import imgServeces2 from './../assets/img/serveces--2.webp'

export const servecesList = [
    {
        linkTo: '/furniture',
        img: imgServeces1 ,
        bgCol:'brown-light',
        title:'Мебель на заказ',
        text:
            [
                'Мебель под заказ по индивидуальным размерам это гарантия отличного качества, долговечность, экологичность и эксклюзивность наших изделий.',
                'Возможность создания своего неповторимого стиля, уникальность и неповторимость каждого изделия.'
            ],
        btnText:'Заказать мебель',
    },
    {
        linkTo: '/workshop',
        img: imgServeces2 ,
        bgCol:'brown',
        title:'Мастер-классы',
        text:
            [
                'Что делать, если память о предках хочется сохранить, но к вашему интерьеру старая и испорченная мебель не подходит?',
                'Правильно!',
                'Отреставрировать её и наслаждаться не только обновлённым видом мебели, но и с теплотой помнить, что эта мебель была сделана талантливыми предками.'
            ],
        btnText:'Купить мастер-класс',
    },
] 
